<template>
  <Popup popupType="tableType" width="588px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">비밀번호 찾기</h3>
        <!-- <p class="desc_tip">
          <span class="ico_purchase ico_exclam"></span>원하시는 공동체를 선택하신 후 정보를 입력해
          주세요
        </p> -->
      </div>
      <div class="body_section">
        <FilterBarBase :btnBasic="false">
          <dl>
            <!-- <dt>공동체</dt>
            <dd>
              <SelectBox
                placeholder=""
                :dataList="companyList"
                :value.sync="selectedCompanyCode"
                class="w208"
                @onChange="onChangeCompany"
              />
            </dd> -->
            <dt>ID (사업자 번호)</dt>
            <dd>
              <div class="group_form">
                <!-- 일단 문자도 입력 가능하게 -->
                <Input class="w134" :value.sync="corprateNum01" />
                <span class="txt_view_c w20">-</span>
                <Input class="w72" :value.sync="corprateNum02" />
                <span class="txt_view_c w20">-</span>
                <Input class="w134" :value.sync="corprateNum03" />
              </div>
            </dd>
            <dt>대표 이메일</dt>
            <dd>
              <InputEmail :emailName.sync="emailName" :emailDomain.sync="emailDomain" />
            </dd>
          </dl>
        </FilterBarBase>
        <p v-if="isSendEmail === 'OK'" class="desc_alert">
          등록하신 대표 이메일로 임시비밀번호를 발송하였습니다.
        </p>
        <p v-else-if="isSendEmail === 'FAIL'" class="desc_alert tc_red">
          입력하신 정보와 일치하는 계정 정보가 없습니다. 확인 후 다시 시도해 주세요.
        </p>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_fourthly btn_medium" @click="onClickClose">닫기</button>
      <button
        v-if="isSendEmail == 'OK'"
        type="submit"
        class="btn_primary btn_medium"
        @click="onClickSubmit"
      >
        재발송
      </button>
      <button v-else type="submit" class="btn_primary btn_medium" @click="onClickSubmit">
        확인
      </button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import SelectBox from "@/components/common/selectBox/SelectBox";
import Input from "@/components/common/input/Input";
import InputEmail from "@/components/common/input/InputEmail";
import ApiService from "@/services/ApiService";

import { mapState } from "vuex";
//import { COMPANY_ACTION } from '@/store/modules/company/action';

export default {
  name: "PasswordPopup",
  components: {
    Popup,
    FilterBarBase,
    SelectBox,
    Input,
    InputEmail,
  },
  data() {
    return {
      corprateNum01: "",
      corprateNum02: "",
      corprateNum03: "",
      emailName: "",
      emailDomain: "",
      isSendEmail: "",

      selectedCompanyCode: "std",
    };
  },
  computed: {
    ...mapState({
      companyList: (state) => state.company.companyList,
    }),
  },
  watch: {
    companyList() {
      if (this.companyList.length < 1) return;
      if (this.selectedCompanyCode) return;

      this.selectedCompanyCode = this.companyList[0].code;

      this.onChangeCompany(this.selectedCompanyCode);
    },
  },
  mounted() {
    // if (this.companyList.length > 0) {
    //   this.selectedCompanyCode = this.companyList[0].code;
    // } else {
    //   this.$store.dispatch(COMPANY_ACTION.GET_COMPANY_LIST);
    // }
  },
  methods: {
    onChangeCompany(to, from) {
      // const result = this.companyList.find( item => item.code === to );
      // if( !result ) return;
      // this.$store.dispatch( COMPANY_ACTION.SET_COMPANY, result );
    },
    onClickClose() {
      this.$emit("onClickClose");
    },

    onClickSubmit() {
      const obj = {
        email: this.emailName + "@" + this.emailDomain,
        loginId: this.corprateNum01 + "-" + this.corprateNum02 + "-" + this.corprateNum03,
        subject: "임시 비밀번호 발급 메일",
      };

      this.sendPassword(obj);
    },

    async sendPassword(obj) {
      const result = await ApiService.shared.password(obj, this.selectedCompanyCode);

      if (result.code === "200") {
        this.isSendEmail = "OK";
      } else {
        this.isSendEmail = "FAIL";
      }
    },
  },
};
</script>
